export default {
    'en': {
        currency: {
            style: 'currency',
            currency: 'CAD',
            currencyDisplay: 'narrowSymbol'
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        },
        percent: {
            style: 'percent',
            useGrouping: false
        }
    },
    'fr': {
        currency: {
            style: 'currency',
            currency: 'CAD',
            currencyDisplay: 'narrowSymbol'
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        },
        percent: {
            style: 'percent',
            useGrouping: false
        }
    }
}
